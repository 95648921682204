import {Component, Input} from '@angular/core';

@Component({
  selector: 'sort-indicator',
  templateUrl: './sort-indicator.component.html',
  styles: [""]
})
export class SortIndicatorComponent {
  @Input() ascending: boolean;
  @Input() trigger: any;
  @Input() value: any;
}
