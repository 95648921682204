import {
  AfterContentInit,
  Component,
  ContentChildren, EventEmitter, Output,
  QueryList,
} from "@angular/core";
import {Tab} from "./tab.component";

@Component({
  selector: 'mytabs',
  template: `
    <div class="tabs">
      <button class="button" [ngClass]="{active: tab.active}" type="button" *ngFor="let tab of tabs" (click)="onSelect(tab)">{{tab.tabTitle}}</button>
    </div>
    <ng-content></ng-content>
  `
})
export class Tabs implements AfterContentInit {
  @ContentChildren(Tab) tabs: QueryList<Tab>;
  @Output('select') select = new EventEmitter<string>();

  ngAfterContentInit(): void {
    this.tabs.changes.subscribe(change => {
      if (!this.tabs.find(tab => tab.active)) {
        setTimeout(() => this.tabs.first.active = true);
      }
    });
  }

  onSelect(tab: Tab | string) {
    if (typeof tab === "string") {
      this.tabs.forEach(t => t.active = (tab === t.tabTitle));
      this.select.emit(tab);
    } else {
      this.tabs.forEach(t => t.active = (tab === t));
      this.select.emit(tab.tabTitle);
    }
  }
}

