import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { RatingsTableComponent } from './ratings-table/ratings-table.component';
import {HttpClientModule} from "@angular/common/http";
import {Tabs} from "./tabs.component";
import {Tab} from "./tab.component";
import { SortIndicatorComponent } from './sort-indicator/sort-indicator.component';



@NgModule({
  declarations: [
    AppComponent,
    RatingsTableComponent,
    Tab,
    Tabs,
    SortIndicatorComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
