import {Component, Input} from "@angular/core";

@Component({
  selector: 'mytab',
  template: `
    <div [hidden]="!active">
      <ng-content></ng-content>
    </div>
  `
})
export class Tab {
  active = false;
  @Input('title') tabTitle: string;
}
