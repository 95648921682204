import {Injectable} from '@angular/core';
import {concat, Observable, of, Subject} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {distinctUntilChanged, flatMap, map, share} from "rxjs/operators";
import {environment} from "../environments/environment";
import {Data} from "./model";

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private dataSource: Observable<Data>;
  private groups: Subject<string> = new Subject();
  private GROUPS_URL = environment.apiUrl + "/cache/groups";

  constructor(private http: HttpClient) { }

  private init(): void {
    if (this.dataSource) return;
    this.dataSource = this.groups.asObservable().pipe(
      distinctUntilChanged(),
      flatMap(group => doQuery(this.http, group)),
      share()
    );
    this.groups.next("All");
  }

  getDataForGroup(group: string): void {
    console.log("get data for " + group);
    this.groups.next(group);
  }

  getGroups(): Observable<string[]> {
    const fromServer =
      (this.http.request("GET", encodeURI(this.GROUPS_URL), { responseType: "text" }) as Observable<string>)
        .pipe(
          map(s => s.split("\n").filter(s => s.length > 0)),
          share()
        );
    return concat(of(["All"]), fromServer);
  }

  public getData(): Observable<Data> {
    this.init();
    return this.dataSource;
  }
}

function doQuery(http: HttpClient, group: string): Observable<Data> {
  console.log("doQuery");
  return http.get(encodeURI(environment.apiUrl + `/cache/${group}.json`), {}) as Observable<Data>;
}

