<div class="tableholder">
  <table *ngIf="data">
    <thead>
    <tr>
      <th (click)="sortByName()">
        Name
        <sort-indicator [ascending]="!descending" trigger="name" [value]="sortBy"></sort-indicator>
      </th>
      <th (click)="sortByMonster()">
        <img src="assets/white-golem.png" height="32px" title="Monster Rating">
        <sort-indicator [ascending]="!descending" trigger="monster" [value]="sortBy"></sort-indicator>
      </th>
      <th (click)="sortByGame(g)" *ngFor="let g of games">
        {{g.name}}
        <sort-indicator [ascending]="!descending" [trigger]="g" [value]="sortBy"></sort-indicator>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let player of players">
      <td>{{player.name}}</td>
      <td class="monster">{{getMonsterRating(player)}}</td>
      <td *ngFor="let g of games" [ngStyle]="getRatingStyle(player, g.id)">{{getTextRating(player, g.id)}}</td>
    </tr>
    </tbody>
  </table>
</div>
