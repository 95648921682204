import {AfterViewInit, Component, OnDestroy, ViewChild} from '@angular/core';
import {Subscription} from "rxjs";
import {DataService} from "./data.service";
import {Tabs} from "./tabs.component";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements AfterViewInit, OnDestroy {
  @ViewChild('tabs') tabs: Tabs;
  private subscription: Subscription;
  groups: string[] = [];

  constructor(public dataService: DataService) {
    this.subscription = dataService.getGroups().subscribe(groups => {
      this.groups = groups;
    });
  }

  ngAfterViewInit() {
    this.tabs.onSelect("All");
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  selectTab(group: string) {
    if (this.groups.indexOf(group) >= 0) {
      this.dataService.getDataForGroup(group);
    }
  }
}
