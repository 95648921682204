<div class="app">
  <mytabs #tabs (select)="selectTab($event)">
    <mytab #me *ngFor="let group of groups" title="{{group}}">
      <ratings-table></ratings-table>
    </mytab>
    <mytab title="About">
      <p>This site was created as an updated version of <a href="https://golem.tasuki.org/">https://golem.tasuki.org</a> to include new games.</p>

      <p>Monster rating for each player is calculated like this:</p>
<pre>
for each game in games {{'{'}}
    if (game.rating &gt; 1500) {{'{'}}
        // we only count games the player is good at, no penalty for being worse-than-default
        monster_rating = monster_rating + game.rating - 1500
    }
}
</pre>
      i.e. in human language, <b>the sum of points exceeding 1500 for all games.</b>

      <p>Go ratings are numeric. Here are the formulas:</p>
      <dl>
        <dt>for dan ranks:</dt><dd><code>GO_ZERO - 50 + (50 * rank_number)</code></dd>
        <dt>for kyu ranks:</dt><dd><code>GO_ZERO + 45 - (50 * rank_number)</code></dd>
        <dt></dt><dd><code>GO_ZERO = 2205</code></dd>

        <li><code>rank_number</code> is simply the decimal number before the rank type</li>
        <li>Few “rating: rank” pairs that might be of interest:
          <ul>
            <li>1500: 14k (with any better rank you are getting monster points)</li>
            <li>2000: 4k</li>
            <li>2150: 1k</li>
            <li>2155: 1d (yes, on LG, the difference between 1k and 1d is not one rank – it is one tenth of a rank</li>
            <li>2355: 5d</li>
          </ul>
        </li>
      </dl>

      <p>How the site works (it's mostly intuitive):</p>
      <ul>
        <li>clicking on game name sorts players by their rating in that game</li>
        <li>clicking on “update” updates that player's ratings </li>
        <li>clicking on player's name takes you to that user's LG profile</li>

      </ul>

      <p>Your comments and ideas for improvement are highly appreciated!</p>

      <hr>
      <p>Designed by Maurizio de Leo. Coded by DrFriendless. Golem icon by <a href="https://www.iconfinder.com/leremy">Gan Khoon Lay</a>.</p>
    </mytab>
  </mytabs>
</div>
