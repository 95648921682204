import {Component} from '@angular/core';
import {Data, Game, Player} from "../model";
import {DataService} from "../data.service";
import {Tab} from "../tab.component";

type PlayerWithRating  = Player & { rating: number };

@Component({
  selector: 'ratings-table',
  templateUrl: './ratings-table.component.html'
})
export class RatingsTableComponent {
  data: Data;
  games: Game[];
  players: PlayerWithRating[];
  descending = true;
  sortBy: "name" | "monster" | Game = "monster";

  constructor(dataService: DataService, tab: Tab) {
    dataService.getData().subscribe(d => {
      if (!tab.active) {
        this.data = undefined;
      } else {
        this.data = d;
      }
      this.processData();
    });
  }

  private processData() {
    if (!this.data) {
      this.games = [];
      this.players = [];
      return;
    }
    this.games = this.data.games.slice();
    this.games.sort((a,b) => a.name.localeCompare(b.name));
    this.players = this.data.players
      .map(p => { return { ...p, rating: this.calcMonsterRating(p) }; })
      .sort((a, b) => b.rating - a.rating);
  }

  LOW = { red: 232, green: 82, blue: 16};
  HIGH = { red: 0, green: 102, blue: 56 };
  BORING = { "background-color": "#f7d98d" };
  BAD = { "background-color": "#de0021" };

  getRatingStyle(player: Player, gid: string): Record<string, any> {
    const r = getRating(player, gid);
    if (r === 0) {
      return this.BORING;
    } else if (r < 1500) {
      return this.BAD;
    } else {
      const result = {};
      const percent = (r > 2400) ? 1 : ((r - 1500) / 900);
      result["background-color"] = this.interpolate(percent);
      return result;
    }
  }

  private interpolate(percent: number): string {
    const red = Math.round(this.LOW.red + (this.HIGH.red - this.LOW.red) * percent);
    const green = Math.round(this.LOW.green + (this.HIGH.green - this.LOW.green) * percent);
    const blue = Math.round(this.LOW.blue + (this.HIGH.blue - this.LOW.blue) * percent);
    return "#" + hex(red) + hex(green) + hex(blue);
  }

  private calcMonsterRating(player: Player): number {
    let rating = 0;
    for (const game of this.games) {
      const pr = getRating(player, game.id);
      if (pr && pr > 1500) {
        rating += pr - 1500;
      }
    }
    return rating;
  }

  // used from template
  getMonsterRating(player: PlayerWithRating): string {
    if (player.rating <= 0) return "";
    return player.rating.toString();
  }

// used from template
  getTextRating(player: Player, gid: string): string {
    const v = getRating(player, gid);
    if (v <= 0) return "";
    return v.toString();
  }

  sortByMonster() {
    if (this.sortBy !== "monster") {
      this.descending = true;
      this.sortBy = "monster";
    } else {
      this.descending = !this.descending;
    }
    this.players.sort((a, b) => {
      const ar = a.rating;
      const br = b.rating;
      if (ar !== br) {
        if (this.descending) return br - ar;
        return ar - br;
      }
      return a.name.localeCompare(b.name);
    });
  }

  sortByGame(game: Game) {
    if (this.sortBy !== game) {
      this.descending = true;
      this.sortBy = game;
    } else {
      this.descending = !this.descending;
    }
    this.players.sort((a, b) => {
      const ar = getRating(a, game.id);
      const br = getRating(b, game.id);
      if (ar !== br) {
        if (this.descending) return br - ar;
        return ar - br;
      }
      return a.name.localeCompare(b.name);
    });
  }

  sortByName() {
    if (this.sortBy !== "name") {
      this.descending = false;
      this.sortBy = "name";
    } else {
      this.descending = !this.descending;
    }
    this.players.sort((a, b) => {
      if (this.descending) return b.name.localeCompare(a.name);
      return a.name.localeCompare(b.name);
    });
  }
}

function getRating(player: Player, gid: string): number {
  for (const r of player.ratings) {
    if (r.gameId === gid) return r.rating
  }
  return 0;
}

function hex(n: number): string {
  if (n < 16) return "0" + n.toString(16);
  return n.toString(16);
}
